import React from "react"
import {graphql,withPrefix} from "gatsby"
import Layout from "../components/Layout"
import images from "../components/images";
import {Helmet} from "react-helmet";

export default ({data}) => {
    return (
        <Layout>

            <Helmet>
                <html lang="en" />
                <title>Privacy Policy - Cosmoally</title>
                <meta name="description" content="We at, CliniExperts Services Private Limited (“Company’) take your privacy very seriously and are committed to being transparent with how we use your information"/>

                <meta name="robots"
                      content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="Privacy Policy - Cosmoally"/>
                <meta property="og:description"
                      content="We at, CliniExperts Services Private Limited (“Company’) take your privacy very seriously and are committed to being transparent with how we use your information. We &hellip; Read More &#8594;"/>
                <meta property="og:url" content="https://cosmoally.com/privacy-policy/"/>
                <meta property="og:site_name" content="Cosmoally"/>
                <meta property="article:modified_time" content="2020-04-15T07:33:19+00:00"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:label1" content="Written by"/>
                <meta name="twitter:data1" content="Cosmoally Team"/>
                <meta name="twitter:label2" content="Est. reading time"/>
                <meta name="twitter:data2" content="14 minutes"/>
                <script type="application/ld+json"
                        className="yoast-schema-graph">{`{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"http://cosmoally.com/#website","url":"http://cosmoally.com/","name":"Cosmoally","description":"Just another WordPress site","potentialAction":[{"@type":"SearchAction","target":"http://cosmoally.com/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://cosmoally.com/privacy-policy/#webpage","url":"https://cosmoally.com/privacy-policy/","name":"Privacy Policy - Cosmoally","isPartOf":{"@id":"http://cosmoally.com/#website"},"datePublished":"2020-04-10T17:52:00+00:00","dateModified":"2020-04-15T07:33:19+00:00","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://cosmoally.com/privacy-policy/"]}]}]}`}</script>
                <link rel="alternate" type="application/rss+xml" title="Cosmoally &raquo; Feed"
                      href="http://cosmoally.com/feed/"/>
                <link rel="alternate" type="application/rss+xml" title="Cosmoally &raquo; Comments Feed"
                      href="http://cosmoally.com/comments/feed/"/>

{/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-158707108-1"></script>
<script src={withPrefix('gtag.js')} type="text/javascript" />

            </Helmet>

            <div className="home_main_background_image inner">
                <img src={images.blogBanner}
                     className="Cosmetic Registration in India: What is the difference between Legal and Actual Manufacturer?"/>
            </div>

            <header className="entry-header text-center mb-4">
                <h1 className="entry-title">Privacy Policy</h1>
            </header>

            <div class="container-fluid  pb-5">
                <div className="container">
                    <br/>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div dangerouslySetInnerHTML={{__html: data.allWordpressPage.nodes[0].content}}/>
                        </div>
                    </div>

                </div>
            </div>


        </Layout>
    )
}

export const pageQuery = graphql`
  query {
    allWordpressPage(filter: {slug: {eq: "privacy-policy"}}) {
    nodes {
      content
      title
    }
  }
  }`
